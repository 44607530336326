import React, {useState} from 'react';
import {IntlProvider} from 'react-intl';
import Greek from '../lang/el.json';
import English from '../lang/en.json';


export const Context = React.createContext();

// const local = navigator.language;
const local = 'el';

let lang;
if (local === 'el') {
   lang = Greek;
}else {
   lang = English;
}

const Wrapper = (props) => {
   const [locale, setLocale] = useState(local);
   const [messages, setMessages] = useState(lang);
   function selectLanguage(e) {
       const newLocale = e.target.dataset.targetLang;

       console.log(newLocale);
       window.dispatchEvent(new Event('localeChanged'));

       setLocale(newLocale);
       if (newLocale === 'el') {
           setMessages(Greek);
           document.documentElement.lang = 'el';
       } else {
           setMessages(English);
           document.documentElement.lang = 'en';
       }
   }
   // console.log(props.children);
   return (
       <Context.Provider value = {{locale, selectLanguage}}>
           <IntlProvider messages={messages} locale={locale}>
               {props.children}
           </IntlProvider>
       </Context.Provider>
   );
}
export default Wrapper;
