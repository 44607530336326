import axios from 'axios';
import alt   from '../alt/alt.js';

class DataActions {
  constructor() {
    const appUrl = process.env.REACT_APP_API_URL;
    this.pagesEndPoint = `${appUrl}/wp-json/wp/v2/pages`; // Endpoint for getting Wordpress Pages
    this.pagesEndPointEn = `${appUrl}/wp-json/wp/v2/pages?lang=en`; // Endpoint for getting Wordpress Pages
    this.postsEndPoint = `${appUrl}/wp-json/wp/v2/posts`; // Endpoint for getting Wordpress Posts
    this.postsEndPointEn = `${appUrl}/wp-json/wp/v2/posts?lang=en`; // Endpoint for getting Wordpress Posts
    this.optionsEndPoint = `${appUrl}/wp-json/wp/v2/options`;
  }

  // Method for getting data from the provided end point url
  api(endPoint) {
    return new Promise((resolve, reject) => {
      axios.get(endPoint).then((response) => {
        resolve(response.data);
      }).catch((error) => {
        reject(error);
      });
    });
  }

  // Method for getting Pages data
  getPages(cb){
    this.api(this.pagesEndPoint).then((response)=>{
      this.getPagesEn(response, cb);
      // this.getPosts(response, cb);
      // console.log(response);
    });
    return true;
  }

  // Method for getting Pages data english
  getPagesEn(pages, cb){
    this.api(this.pagesEndPointEn).then((response)=>{
      const pagesEn     = response
      const payload   = { pages, pagesEn };

      this.getPosts(payload, cb);
      // console.log(response);
    });
    return true;
  }

  // Method for getting Posts data
  getPosts(pagesEnPages, cb){
    this.api(this.postsEndPoint).then((response)=>{
      const posts     = response
      const {pages, pagesEn} = pagesEnPages
      const payload   = { pages, pagesEn, posts };

      this.getPostsEn(payload, cb); // Pass returned data to the store
      // console.log(payload)
      // cb(payload); // This callback will be used for dynamic rout building
    });
    return true;
  }


  // Method for getting Posts data
  getPostsEn(pagesEnPagesPosts, cb){
    this.api(this.postsEndPointEn).then((response)=>{
      const postsEn     = response
      const {pages, pagesEn, posts} = pagesEnPagesPosts
      const payload   = { pages, pagesEn, posts, postsEn };

      this.getSuccess(payload); // Pass returned data to the store
      // console.log(payload)
      cb(payload); // This callback will be used for dynamic rout building
    });
    return true;
  }

  // Method for getting Options data
  getOptions(cb){
    this.api(this.optionsEndPoint).then((response)=>{
      this.getPosts(response, cb)
    });
    return true;
  }

  // This returnes an object with Pages and Posts data together
  // The Alt Store will listen for this method to fire and will store the returned data
  getSuccess(payload){
    return payload;
  }
}

export default alt.createActions(DataActions);
