import React from 'react';
import DataStore from '../flux/stores/DataStore.js';
import Masonry from 'react-masonry-component';
import {injectIntl} from 'react-intl';


const masonryOptions = {
    transitionDuration: 1000,
    hiddenStyle: { opacity: 0 },
    visibleStyle: { opacity: 1 },
    // columnWidth: '.grid-sizer'
};

const imagesLoadedOptions = { background: '.my-bg-image-el' };

const pageCount = 12;

class News extends React.Component {
  constructor(props) {
    super(props);
    let posts = DataStore.getAllPosts();
    // let posts = DataStore.getAllPosts(this.props.intl.locale);
    // console.log(posts.length / pageCount > 1);

    this.state = {
      allPosts: posts,
      posts: posts.slice(0, pageCount),
      page: 1,
      hasMorePages: posts.length / pageCount > 1
    }
  }

  parseDate(dateStr) {
  	let dateParts =  dateStr.split('T'),
  			parts = dateParts[0].match(/^(\d{1,4})-(\d{1,2})-(\d{1,2})$/),
  			date = '';
  	if (parts) {
  		date = [parseInt(parts[3], 10), parseInt(parts[2], 10) - 1, parseInt(parts[1], 10)].join('.');
  	}

  	return date;
  }

  moreNews() {
    console.log('show more news');
    // const newPosts = document.querySelectorAll('.post.hidden');
    // newPosts.forEach((post, i) => {
    //   if(i < 6) {
    //     post.classList.remove('hidden');
    //   }
    // });
    // // this.masonry('reload');
    // console.log(this.masonry);
    let newPages = this.state.allPosts.slice(0,(this.state.page + 1) * pageCount);
    console.log(newPages.length, this.state.allPosts.length);
    console.log(newPages.length < this.state.allPosts.length);

    this.setState({
      posts: newPages,
      page: this.state.page + 1,
      hasMorePages: newPages.length < this.state.allPosts.length
    })
  }

  render() {
    // let posts = DataStore.getAllPosts();
    let posts = DataStore.getAllPosts(this.props.intl.locale);
    // console.log(this.state.posts);
    const WithLink = ({ link, className, children }) => (link ?
      <a href={link} className={className} target="_blank" rel="noopener noreferrer">
        {children}
      </a>
      : children
    );

    return (
      <section className="posts beige">
        <header className="news-header">
          <h2>Press</h2>
        </header>
        <div className="cnt">
          <Masonry
            ref={function(c) {this.masonry = this.masonry || c.masonry;}.bind(this)}
            className={'post-grid'} // default ''
            elementType={'div'} // default 'div'
            options={masonryOptions} // default {}
            disableImagesLoaded={false} // default false
            updateOnEachImageLoad={false} // default false and works only if disableImagesLoaded is false
            imagesLoadedOptions={imagesLoadedOptions} // default {}
            >

          {posts.map((post, i) => {
            return <div key={i} className={`post`}>
              <WithLink link={post.acf.link}>
                <img src={post.fimg_url} alt={post.title.rendered} />
              </WithLink>


              <div className="date">{this.parseDate(post.date)}</div>
              <div className="title" dangerouslySetInnerHTML={{__html:post.title.rendered}} />
              <div className="excerpt" dangerouslySetInnerHTML={{__html:post.content.rendered}} />
            </div>
          })}
          </Masonry>
          <button className={`button more-news ${!this.state.hasMorePages ? 'disabled':''}`} onClick={this.moreNews.bind(this)}>Περισσοτερα νεα</button>
        </div>
      </section>
    );
  }
}

export default injectIntl(News, {withRef: true});
