import React from 'react';
import DataStore from '../flux/stores/DataStore.js';
import Layout from './layout.js';
// import Footer from './footer.js';
import Footer from './footer-new.js';
import InstaFeed from './insta-feed.js';
import News from './news.js';
import {Helmet} from "react-helmet";
import {injectIntl} from 'react-intl';


class StayTuned extends React.Component {
  render() {
    let stay = DataStore.getPageBySlug('news', this.props.intl.locale);
    // console.log(stay);

    return (
      <div>
        <Layout>
          <Helmet>
            <title>HOOCUT - {stay.title.rendered}</title>
            <meta name="description" content={stay.yoast.metadesc} />
            <meta name="keywords" content={stay.yoast.metakeywords} />

            <meta property="og:title" content={stay.yoast['opengraph-title'] || 'HOOCUT - Stay Tuned'} />
            <meta name="og:description" content={stay.yoast['opengraph-description'] || stay.yoast.metadesc} />
            <meta property="og:image" content={stay.yoast['opengraph-image'] || stay.acf.intro_background.url} />

            <meta name="twitter:title" content={stay.yoast['twitter-title'] || 'HOOCUT - Stay Tuned'} />
            <meta name="twitter:description" content={stay.yoast['twitter-description'] || stay.yoast.metadesc} />
            <meta name="twitter:image" content={stay.yoast['twitter-image'] || stay.yoast['opengraph-image'] || stay.acf.intro_background.url} />
          </Helmet>
          <section id="intro-section" className="intro-section"  style={{
            backgroundImage: 'url(' + stay.acf.intro_background.url + ')'
          }}>
            <h1>{stay.acf.intro_tagline}</h1>
          </section>

          <News />
        </Layout>
        <Footer nextImage={stay.acf.first_image} nextSlug={stay.acf.page_slug} nextPage={stay.acf.next_page} />
      </div>
    );
  }
}

export default injectIntl(StayTuned, {withRef: true});


// <InstaFeed />
