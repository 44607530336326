import React from 'react';
// import ReactDOM from 'react-dom';
import {render} from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './scripts/App';
import registerServiceWorker from './registerServiceWorker';
import DataActions          from './scripts/flux/actions/DataActions.js';


// import {IntlProvider, FormattedMessage, FormattedNumber} from 'react-intl';
// import en from 'react-intl/locale-data/en';
// import el from 'react-intl/locale-data/el';

// ReactDOM.render((
//     <BrowserRouter>
//       <App />
//     </BrowserRouter>
//   ), document.getElementById('root'));
// registerServiceWorker();


class AppInitializer {

    run() {
        DataActions.getPages((response)=>{
          render((
              <BrowserRouter>
                <App response={response} />
              </BrowserRouter>
            ), document.getElementById('root'));
            registerServiceWorker();
        });
    }
}


// if (!global.Intl) {
//     require.ensure([
//         // 'intl',
//         'intl/locale-data/en.js'
//     ], function (require) {
//         // console.log('polyfilling intl');
//         // require('intl');
//         require('intl/locale-data/en.js');
//         require('intl/locale-data/el.js');
//         new AppInitializer().run();
//     });
// } else {
//     // console.log('not polyfilling intl');
//     new AppInitializer().run();
// }



new AppInitializer().run();
