import React from 'react'; //, { Component }

class FooterEmailIcon extends React.Component {
  render() {
      return (
        <svg className="footer-email-icon-svg" width="44" height="36" viewBox="0 0 44 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M19.558 0.644304C20.2271 0.255592 21.0058 0.0339963 21.8095 0.00360879C22.6132 -0.0267787 23.4111 0.135205 24.1164 0.471949L24.4398 0.644304L42.0398 10.8573C42.5912 11.1771 43.0525 11.6012 43.3892 12.098C43.726 12.5947 43.9295 13.1512 43.9846 13.726L44 14.0439V32.1699C44.0004 33.1362 43.5811 34.0669 42.8263 34.7754C42.0715 35.484 41.0369 35.918 39.93 35.9904L39.6 36H4.4C3.28993 36.0003 2.22075 35.6354 1.40679 34.9783C0.592835 34.3213 0.0942553 33.4207 0.0110003 32.4572L0 32.1699V14.0439C1.14209e-05 13.4673 0.149588 12.8981 0.437573 12.3789C0.725557 11.8596 1.14452 11.4036 1.6632 11.045L1.9602 10.8573L19.558 0.644304ZM22 3.83095L6.1666 13.0194L22 22.2078L37.8334 13.0194L22 3.83095Z" fill="#E7DBC8"/></svg>
      );
  }
}

export default FooterEmailIcon;
