// import React from 'react'; //, { Component }
import React from 'react';
import {Link} from 'react-router-dom';
import TruePitta from '../../svg/true-pitta.js';
import FbIcon from '../../svg/fb-icon.js';
import InstagramIcon from '../../svg/instagram-icon.js';
import DataStore from '../flux/stores/DataStore.js';
// import _ from 'lodash'
import LangSwitcher from './lang-switcher';
import {FormattedMessage} from 'react-intl';


class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        menuOpen: false,
        headerNormal: false,
        locale: 'en',
      }


    this.toggleMenu = this.toggleMenu.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  componentDidMount() {
    // if(this.props.location.pathname === '/') {
        window.addEventListener('scroll', this.handleScroll, {passive: true});
    // }

  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll, {passive: true});
  }

  handleScroll(event) {
    if(window.scrollY > 100) {
      if(!this.state.headerNormal) {
        this.setState({
          headerNormal: true
        });
      }
    }else{
      if(this.state.headerNormal) {
        this.setState({
          headerNormal: false
        });
      }
    }
  }

  toggleMenu() {
    // console.log('will fondle menu for treats');
    this.setState({
      menuOpen: !this.state.menuOpen
    });
  }

  render() {
    let options = DataStore.getPageBySlug('options');
    // console.log('options are');
    // console.log(options.acf.online_order);
      // let allPages = DataStore.getAllPages();
      // allPages = _.sortBy(allPages, [function(page) { return page.menu_order; }]); // Sort pages by order

    return (
      <header className={`site-header ${this.state.headerNormal ? 'normal' : ''} ${this.state.menuOpen ? 'active' : ''}`}>
        <div  id="nav-cnt" className={'nav-cnt'}>
          <nav id="main-nav" className="main-nav">
            <div><Link to="/hoocut"><FormattedMessage id="nav.hoocut" /></Link></div>
            <div><Link to="/chefs"><FormattedMessage id="nav.chefs" /></Link></div>
            <div><Link to="/menu"><FormattedMessage id="nav.menu" /></Link></div>
            <div><Link to="/news"><FormattedMessage id="nav.news" /></Link></div>
          </nav>
          <LangSwitcher className="lang-switcher-mobile" />
          <div className="infos">

            <span>T:</span><a href="tel:+302103240026">21 0324 0026</a><br/>
            <span>E:</span><a href="mailto:info@hoocut.com">info@hoocut.com</a><br/>
            <a href="https://www.facebook.com/hoocut" target="_blank" className="fb-link"><FbIcon /></a>
            <a href="https://www.instagram.com/hoocut/" target="_blank" className="instagram-link"><InstagramIcon /></a>
          </div>
          <div className="credits">
            <div>&copy; HOOCUT {new Date().getFullYear()}</div>
            <div><Link to="/privacy-policy">Terms and conditions / privacy policy</Link></div>
            <div>Website Created by <a href="https://nowhere.studio" target="_blank" rel="noopener noreferrer">Nowhere Studio</a></div>
          </div>
        </div>
        <Link to="/" className="home-link logo">
          <svg className="logo-svg" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 34.75 54.07"><g><g><path className="logo-path" d="M33.9,6.62A23.31,23.31,0,0,0,17.38,0,23.32,23.32,0,0,0,.85,6.62,2.79,2.79,0,0,0,0,9.08l0,.13,4.21,20L9,24.56,6.66,13.61l11.18,5.21-13,13,4.4,20.91a1.66,1.66,0,0,0,1.62,1.32H23.9a1.66,1.66,0,0,0,1.62-1.32L34.69,9.19A2.78,2.78,0,0,0,33.9,6.62ZM20.74,48.52H14l-1.18-5.59,8.28,3.86Zm1.54-7.31L11.4,36.14l-.52-2.5L27.34,17.17ZM22,14.65,8,8.1a18,18,0,0,1,9.41-2.55A18,18,0,0,1,27.86,8.8Z"/></g></g></svg>
          <svg className="logo-rights" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10.44 10.14'><g><g><path d='M6.39,5.46A1.67,1.67,0,0,0,7.3,4a1.66,1.66,0,0,0-1.78-1.7H3.31V7.77H4.57V5.66h.66l.84,2.11H7.4ZM5.48,4.6H4.57V3.32H5.5A.6.6,0,0,1,6.13,4,.62.62,0,0,1,5.48,4.6Z'/><path d='M4.62,10.14V8.85A3.68,3.68,0,0,1,1.38,5.08,3.58,3.58,0,0,1,4.62,1.3V0A5,5,0,0,0,0,5.08a4.9,4.9,0,0,0,4.62,5.06'/><path d='M9,5.08A3.62,3.62,0,0,1,5.71,8.86v1.27a5.09,5.09,0,0,0,4.73-5.05A5,5,0,0,0,5.71,0V1.3A3.59,3.59,0,0,1,9,5.08'/></g></g></svg>
        </Link>
        <TruePitta className="true-pitta" />
        <div className="order-online-cnt"><a href={options.acf.online_order} className="button order-online-bottom-btn" target="_blank"><FormattedMessage id="header.order"  /></a><div className="phone-order"><FormattedMessage id="header.phoneOrder"  />: <a href="tel:+302103240026">210 3240026</a></div></div>
        <div className="open-times">
          <LangSwitcher />
          <a href={options.acf.online_order} target="_blank" className="order-lnk button"><FormattedMessage id="header.order"  /></a>
          <a href={options.acf.facebook.url} className="fb-icon" target="_blank"><FbIcon /></a>
          <a href={options.acf.instagram.url} className="instagram-icon" target="_blank"><InstagramIcon /></a>
        </div>

        <button id="menu-btn"
                className={'menu-btn' + (this.state.menuOpen ? ' active': '') }
                onClick={this.toggleMenu}
        ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30"><g><g><circle className="circle" cx="15" cy="15" r="15"/><path className="cross" d="M16,15.88v4H14.05v-4H10V14.06h4.05V10.12H16v3.94h4v1.82Z"/></g></g></svg></button>
      </header>
    );
  }
}

export default Header;
