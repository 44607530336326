import React from 'react';
// import DataStore from '../flux/stores/DataStore.js';
import { withGoogleMap, GoogleMap, Marker, Polygon } from "react-google-maps";
import { compose, withProps, lifecycle } from "recompose";
import withScriptjs from "react-google-maps/lib/withScriptjs";

const googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.34&key=AIzaSyCUM8EpR6-jV0cG-n6sTvJfhQKpKLgC86M";
const  icon = {
  // url: 'data:image/svg+xml;utf-8, <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72 72" width="72" height="72"><defs><style>.cls-1{fill:lime;opacity:0.9;}.cls-2{fill:#fff;}</style></defs><g><g><circle class="cls-1" cx="36" cy="36" r="36"/><polygon class="cls-2" points="34.25 26.18 34.32 28.18 42.71 27.88 25.74 44.84 27.16 46.25 44.13 29.29 43.82 37.68 45.82 37.75 46.26 25.75 34.25 26.18"/></g></g></svg>',
  url: 'https://hoocut.com/wp/wp-content/uploads/2018/10/map-pin@2x.png',
  // anchor: window.innerWidth < 600 ? {x: 18, y: 18} : {x: 36, y: 36},
  scaledSize: window.innerWidth < 600 ? {width: 38, height: 59} : {width: 76, height: 118}
}

const stylez = [
    {
        "featureType": "all",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#ff0000"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#6e3a35"
            },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#5a524f"
            },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "administrative",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "administrative.locality",
        "elementType": "labels.icon",
        "stylers": [
            {
                "color": "#6e3a35"
            },
            {
                "saturation": "0"
            },
            {
                "visibility": "on"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#efebea"
            },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#af9d94"
            },
            {
                "saturation": "0"
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#5a524f"
            },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "landscape",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "landscape.man_made",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "geometry",
        "stylers": [
            {
                "hue": "#ff0000"
            },
            {
                "saturation": "34"
            },
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#5a524f"
            },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "poi",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#5a524f"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "road",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            },
            {
                "lightness": "60"
            },
            {
                "gamma": "1.00"
            },
            {
                "hue": "#ff0000"
            },
            {
                "saturation": "-90"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#ffffff"
            },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "road.highway",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "simplified"
            },
            {
                "hue": "#ff0000"
            },
            {
                "weight": "0.01"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry.fill",
        "stylers": [
            {
                "color": "#bfb1a9"
            },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "geometry.stroke",
        "stylers": [
            {
                "color": "#bfb1a9"
            },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#5a524f"
            },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "transit",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "on"
            },
            {
                "hue": "#ff0000"
            },
            {
                "saturation": "-90"
            },
            {
                "lightness": "0"
            },
            {
                "gamma": "1.00"
            },
            {
                "weight": "1"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "all",
        "stylers": [
            {
                "color": "#d2cac7"
            },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text",
        "stylers": [
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.fill",
        "stylers": [
            {
                "color": "#5a524f"
            },
            {
                "saturation": "0"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.text.stroke",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    },
    {
        "featureType": "water",
        "elementType": "labels.icon",
        "stylers": [
            {
                "visibility": "off"
            }
        ]
    }
];

let polygonCoords = [
  {lat: 37.984394, lng: 23.728118},
];

const polygonOptions = {
  strokeColor: '#FF0000',
  strokeOpacity: 0.8,
  strokeWeight: 2,
  fillColor: '#FF0000',
  fillOpacity: 0
}



const MapWithControlledZoom = compose(
  withProps({
    googleMapURL: googleMapURL,
    loadingElement: <div className="loading-element" style={{ height: `10vh` }} />,
    containerElement: <div className="map-container" style={{ height: `100%` }} />,
    mapElement: <div className="google-map" style={{ height: `100%` }} />,
  }),
  lifecycle({
    componentWillMount() {
      const refs = {}

      this.setState({
        zoom: 13,
        isPolygonShown: false,
        onMapMounted: ref => {
          refs.map = ref;
          this.testBtn = document.querySelector('#delivery-zone-btn');
          this.testBtn.addEventListener('click', () => {this.setState({zoom: window.innerWidth < 600 ? 13 : 14}); setTimeout(()=>{this.setState({isPolygonShown: true})}, 1000)} );

          // const mapInst = refs.map.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED;
          // console.log(window.google);
          // var scale = Math.pow(2, refs.map.getZoom());
          // console.log(mapInst.getProjection());
          //
          // // var mapCenter = refs.maps.getCenter();
          // var centerLatLng = new window.google.maps.LatLng({lat: mapInst.center.lat(), lng: mapInst.center.lng()});
          // var offsetx = '0';
          // var offsety = '20';







          // var worldCoordinateCenter = refs.map.getProjection().fromLatLngToPoint(centerLatLng);
          // var pixelOffset = new window.google.maps.Point((offsetx/scale) || 0,(offsety/scale) ||0);
          //
          // var worldCoordinateNewCenter = new window.google.maps.Point(
          //     worldCoordinateCenter.x - pixelOffset.x,
          //     worldCoordinateCenter.y + pixelOffset.y
          // );
          //
          // console.log(worldCoordinateNewCenter);
          //
          // var newCenter = refs.map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);
          //
          // refs.map.setCenter(newCenter);





        },
        onZoomChanged: () => {
          this.setState({
            zoom: refs.map.getZoom()
          })
        }
      })
    },
  }),
  withScriptjs,
  withGoogleMap
)(props =>
  <GoogleMap
    className="google-map"
    defaultCenter={{ lat: parseFloat(props.lat), lng: parseFloat(props.long) }}
    defaultZoom={16}
    zoom={props.zoom}
    ref={props.onMapMounted}
    onZoomChanged={props.onZoomChanged}
    defaultOptions={{
        scrollwheel: false,
        mapTypeControl: false,
        streetViewControl: false,
        styles: stylez
      }}
    onClick={props.onMapClick}
  >
    {props.isPolygonShown ?
    <Polygon
      paths={polygonCoords}
      options={polygonOptions}
    />:null}
    {props.markers.map((marker, i) => (
      <Marker
        key = {i}
        icon={icon}
        {...marker}
        onClick={props.onClick}
        onClick={props.onClick.bind(this,marker)}
      />
    ))}
  </GoogleMap>
);

class HoocutMap extends React.Component {
  render() {
    const markers = [
      {
        id: 1,
        position:{
          lat: parseFloat(this.props.lat) || 37.976857,
          lng: parseFloat(this.props.long) || 23.728079
        },
        url: this.props.markerUrl
      },
      {
        id: 2,
        position:{
          lat: parseFloat(this.props.lat2),
          lng: parseFloat(this.props.long2)
        },
        url: this.props.markerUrl2
      },
      {
        id: 3,
        position:{
          lat: parseFloat(this.props.lat3),
          lng: parseFloat(this.props.long3)
        },
        url: this.props.markerUrl3
      },

        ];
    polygonCoords = this.props.coordinates;

    const onClick = (e) => {
      console.log(e.url);
      // if(this.props.markerUrl.length > 0){
      //   window.open(this.props.markerUrl);
      // }
      if(e.url.length > 0) {
        window.open(e.url);
      }
    }

    // const offsetCenter = function(latlng, offsetx, offsety) {
    //
    //   // latlng is the apparent centre-point
    //   // offsetx is the distance you want that point to move to the right, in pixels
    //   // offsety is the distance you want that point to move upwards, in pixels
    //   // offset can be negative
    //   // offsetx and offsety are both optional
    //
    //   var scale = Math.pow(2, map.getZoom());
    //
    //   var worldCoordinateCenter = map.getProjection().fromLatLngToPoint(latlng);
    //   var pixelOffset = new google.maps.Point((offsetx/scale) || 0,(offsety/scale) ||0);
    //
    //   var worldCoordinateNewCenter = new google.maps.Point(
    //       worldCoordinateCenter.x - pixelOffset.x,
    //       worldCoordinateCenter.y + pixelOffset.y
    //   );
    //
    //   var newCenter = map.getProjection().fromPointToLatLng(worldCoordinateNewCenter);
    //
    //   map.setCenter(newCenter);
    // }

    return (
      <div className="map" style={{ height: 'inherit', width: '50%' }}>
        <button id="delivery-zone-btn" className="delivery-zone-btn button" >DELIVERY ZONE</button>
        <MapWithControlledZoom
          markers={markers}
          lat={this.props.lat}
          long={this.props.long}
          onClick={onClick}
        />
      </div>
    );
  }
}

export default HoocutMap;
