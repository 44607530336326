import React, {useContext} from 'react';

import {Context} from "./wrapper";



const LangSwitcher = (props) =>{
  const context = useContext(Context);

  return <div className={`lang-switcher ${props.className || ''}`}>
    <ul>
      <li className={context.locale==="el" ? "active-lang" : null} data-target-lang="el" onClick={context.selectLanguage}>ΕΛ</li>
      <li className={context.locale==="en" || context.locale==="en-US" ? "active-lang" : null} data-target-lang="en" onClick={context.selectLanguage}>EN</li>
    </ul>
  </div>
}

export default LangSwitcher;
