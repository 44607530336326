export function scrollIt(container, destination, duration = 200, easing = 'linear', callback) {

  const easings = {
    linear(t) {
      return t;
    },
    easeInQuad(t) {
      return t * t;
    },
    easeOutQuad(t) {
      return t * (2 - t);
    },
    easeInOutQuad(t) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    },
    easeInCubic(t) {
      return t * t * t;
    },
    easeOutCubic(t) {
      return (--t) * t * t + 1;
    },
    easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    },
    easeInQuart(t) {
      return t * t * t * t;
    },
    easeOutQuart(t) {
      return 1 - (--t) * t * t * t;
    },
    easeInOutQuart(t) {
      return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;
    },
    easeInQuint(t) {
      return t * t * t * t * t;
    },
    easeOutQuint(t) {
      return 1 + (--t) * t * t * t * t;
    },
    easeInOutQuint(t) {
      return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t;
    }
  };

  const start = container === window ? container.scrollY : container.scrollTop;
  const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();
  const documentHeight = Math.max(container.scrollHeight, container.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
  const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
  const destinationOffset = typeof destination === 'number' ? destination : destination.offsetTop;
  const destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset);

  if ('requestAnimationFrame' in window === false) {
    container.scroll(0, destinationOffsetToScroll);
    if (callback) {
      callback();
    }
    return;
  }

  function scroll() {
    const now = 'now' in window.performance ? performance.now() : new Date().getTime();

    const time = Math.min(1, ((now - startTime) / duration));
    const timeFunction = easings[easing](time);
    container.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));

    if (container === window ? Math.round(container.pageYOffset) === destinationOffsetToScroll : Math.round(container.scrollTop) === destinationOffsetToScroll) {
      if (callback) {
        callback();
      }
      return;
    }

    requestAnimationFrame(scroll);
  }

  scroll();
}






export function scrollItV(container, destination, duration = 200, easing = 'linear', direction = 'veritcal', callback) {

  const easings = {
    linear(t) {
      return t;
    },
    easeInQuad(t) {
      return t * t;
    },
    easeOutQuad(t) {
      return t * (2 - t);
    },
    easeInOutQuad(t) {
      return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
    },
    easeInCubic(t) {
      return t * t * t;
    },
    easeOutCubic(t) {
      return (--t) * t * t + 1;
    },
    easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
    },
    easeInQuart(t) {
      return t * t * t * t;
    },
    easeOutQuart(t) {
      return 1 - (--t) * t * t * t;
    },
    easeInOutQuart(t) {
      return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * (--t) * t * t * t;
    },
    easeInQuint(t) {
      return t * t * t * t * t;
    },
    easeOutQuint(t) {
      return 1 + (--t) * t * t * t * t;
    },
    easeInOutQuint(t) {
      return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * (--t) * t * t * t * t;
    }
  };

  const start = direction === 'vertical' ? (container === window ? container.scrollY : container.scrollTop) : (container === window ? container.scrollX : container.scrollLeft);
  // console.log(start);
  const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();
  const documentHeight = Math.max(container.scrollHeight, container.offsetHeight, document.documentElement.clientHeight, document.documentElement.scrollHeight, document.documentElement.offsetHeight);
  const documentWidth = Math.max(container.scrollWidth, container.offsetWidth, document.documentElement.clientWidth, document.documentElement.scrollWidth, document.documentElement.offsetWidth);
  const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.getElementsByTagName('body')[0].clientHeight;
  const windowWidth = container === window ? window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth : container.offsetWidth;
  const destinationOffset = typeof destination === 'number' ? destination : (direction === 'vertical' ? destination.offsetTop : destination.offsetLeft);
  // console.log(`destinationOffset is ${destinationOffset}`);
  const destinationOffsetToScroll = Math.round(documentHeight - destinationOffset < windowHeight ? documentHeight - windowHeight : destinationOffset);
  const horizontalDestinationOffsetToScroll = Math.round(documentWidth - destinationOffset < windowWidth ? documentWidth - windowWidth : destinationOffset);

  if ('requestAnimationFrame' in window === false) {
    if(direction === 'vertical') {
      container.scroll(0, destinationOffsetToScroll);
    } else {
      container.scroll(horizontalDestinationOffsetToScroll, 0);
    }

    if (callback) {
      callback();
    }
    return;
  }

  function scroll() {
    const now = 'now' in window.performance ? performance.now() : new Date().getTime();
    const time = Math.min(1, ((now - startTime) / duration));
    const timeFunction = easings[easing](time);
    // console.log(container.scrollLeft);
    if(direction === 'vertical') {
      container.scroll(0, Math.ceil((timeFunction * (destinationOffsetToScroll - start)) + start));
    } else {
      container.scroll(Math.ceil((timeFunction * (horizontalDestinationOffsetToScroll - start)) + start), 0);
    }

    if(direction === 'vertical') {
      if (container === window ? Math.round(container.pageYOffset) === destinationOffsetToScroll : Math.round(container.scrollTop) === destinationOffsetToScroll) {
        if (callback) {
          callback();
        }
        return;
      }
    } else {
      if (container === window ? Math.round(container.pageXOffset) === horizontalDestinationOffsetToScroll : Math.round(container.scrollLeft) === horizontalDestinationOffsetToScroll) {
        if (callback) {
          callback();
        }
        return;
      }
    }


    requestAnimationFrame(scroll);
  }

  scroll();
}








export function lockScrolling() {
  // console.log('locking scrolling');
  let html = document.body.parentNode,
      body = document.body,
      initWidth = body.offsetWidth,
      initHeight = body.offsetWidth,
      scrollPosition,
      marginR,
      marginB,
      style = window.getComputedStyle(html);

  scrollPosition = [
      window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft,
      window.pageYOffset || document.documentElement.scrollTop  || document.body.scrollTop
  ];

  html.dataset.scrollPosition = scrollPosition;
  html.dataset.previousOverflowY = style.getPropertyValue('overflow-y');
  html.style.overflowY = 'hidden';
  window.scrollTo(scrollPosition[0], scrollPosition[1]);
  marginR = body.outerWidth-initWidth;
  marginB = body.outerHeight-initHeight;
  body.style.marginRight = marginR + 'px';
  body.style.marginBottom = marginB + 'px';
  this.scrollLocked = true;
}

export function unlockScrolling() {
  // console.log('unlocking scrolling');
  let html = document.body.parentNode,
      body = document.body,
      scrollPosition;

  html.style.overflowY = html.dataset.previousOverflowY;
  scrollPosition = html.dataset.scrollPosition;
  window.scrollTo(scrollPosition[0], scrollPosition[1]);
  body.style.marginRight = 0;
  body.style.marginBottom = 0;
  this.scrollLocked = false;
}


/* Indicative use

scrollIt(
  cnt,
  window.innerHeight,
  400,
  'easeOutQuad',
  () => console.log(`Just finished scrolling to ${window.pageYOffset}px`)
);

*/
