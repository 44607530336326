import React from 'react';
import DataStore from '../flux/stores/DataStore.js';
import Layout from './layout.js';
// import Footer from './footer.js';
import Footer from './footer-new.js';
import TheChefs from './the-chefs.js';
import {Helmet} from "react-helmet";
import {injectIntl} from 'react-intl';


class Chefs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chefBg: null
    }
  }

  componentDidMount() {
    this.chooseBg();

    window.addEventListener('resize', this.chooseBg.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.chooseBg.bind(this));
  }

  chooseBg() {
    let chefs = DataStore.getPageBySlug('chefs');
    if(window.innerWidth > 900) {
      this.setState({
        chefBg: chefs.acf.intro_background.url
      });
    } else {
      if(chefs.acf.intro_background_mobile) {
        this.setState({
          chefBg: chefs.acf.intro_background_mobile.url
        });
      } else {
        this.setState({
          chefBg: chefs.acf.intro_background.url
        });
      }
    }
  }

  render() {
    let chefs = DataStore.getPageBySlug('chefs', this.props.intl.locale);

    return (
      <div>
        <Layout>
          <Helmet>
            <title>HOOCUT - {chefs.title.rendered}</title>
            <meta name="description" content={chefs.yoast.metadesc} />
            <meta name="keywords" content={chefs.yoast.metakeywords} />

            <meta property="og:title" content={chefs.yoast['opengraph-title'] || 'HOOCUT - Chefs'} />
            <meta name="og:description" content={chefs.yoast['opengraph-description'] || chefs.yoast.metadesc} />
            <meta property="og:image" content={chefs.yoast['opengraph-image'] || chefs.acf.intro_background.url} />

            <meta name="twitter:title" content={chefs.yoast['twitter-title'] || 'HOOCUT - Chefs'} />
            <meta name="twitter:description" content={chefs.yoast['twitter-description'] || chefs.yoast.metadesc} />
            <meta name="twitter:image" content={chefs.yoast['twitter-image'] || chefs.yoast['opengraph-image'] || chefs.acf.intro_background.url} />
          </Helmet>
          <section id="intro-section" className="intro-section"  style={{
            backgroundImage: 'url(' + this.state.chefBg + ')'
          }}>
            <h1>{chefs.acf.intro_tagline}</h1>
          </section>
          <section className="center first-content">
            <div dangerouslySetInnerHTML={{ __html: chefs.acf.first_content }} />
          </section>
          <TheChefs {...this.props} data={chefs} />
        </Layout>
        <Footer nextImage={chefs.acf.first_image} nextSlug={chefs.acf.page_slug} nextPage={chefs.acf.next_page} />
      </div>
    );
  }
}

export default injectIntl(Chefs, {withRef: true});
