import React from 'react';
import {scrollIt} from './helper-lib.js';

class TheChefs extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      storeId: 'chef1',
      storeText: this.props.data.acf.chef_1_text,
      storeImgUrl: this.props.data.acf.chef_1_image.url,
      activeChef:'chef1'
    }

    this.scrollToChef.bind(this);
  }

  componentDidMount() {
    // this.setState({
    //   image: this.props.data[0].image
    // });

    window.addEventListener('scroll', this.handleScroll.bind(this));

    // cache menu menu_sections
    // this.menuSections = document.querySelectorAll('.section');
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll.bind(this));
  }

  handleScroll() {
    let distFromTop = this.convertRemToPixels(11.85); // 6.25 (header) + 5.6 section top padding
    // let imgHalfHeight = ((window.innerWidth - this.convertRemToPixels(10))/ 2 - this.convertRemToPixels(2)) / 3;

    document.querySelectorAll('.chef-bios .chef').forEach((chef, i)=>{
      if(chef.getBoundingClientRect().top <= distFromTop && chef.getBoundingClientRect().bottom > distFromTop) {
        if(this.state.activeChef !== chef.dataset.id) {
          this.setState({
            activeChef: chef.dataset.id
          });
        }
      }
    })
  }

  convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

  scrollToChef(e) {
    // this.setState({
    //   storeId: e.target.dataset.id,
    //   storeText: e.target.dataset.text,
    //   storeImgUrl: e.target.dataset.img
    // });
    // console.log(`.${e.target.dataset.id}`);
    let chef = document.querySelector(`.${e.target.dataset.id}`);
    // console.log(chef.offsetTop);
    let rect = chef.getBoundingClientRect();
    let offsetTop = window.pageYOffset + rect.top;// - rect.height;
    // console.log(offsetTop);
    document.querySelector('.main').classList.add('inhibit-animation');


    scrollIt(
      window,
      // chef.offsetTop - this.convertRemToPixels(11.85),
      offsetTop - this.convertRemToPixels(11.8),
      500,
      'easeOutQuad',
      () => {
        document.querySelector('.main').classList.remove('inhibit-animation');
      }
    );
  }

  render() {
    return (
      <section id="the-store" className={"the-store beige " + this.state.activeChef}>
        <div className="cnt">
          <div className="left">
            <ul className="chef-list store-sections">
              <li><button
                    onClick={this.scrollToChef.bind(this)}
                    data-id="chef1"
                    data-text={this.props.data.acf.chef_1_text }
                    data-img={this.props.data.acf.chef_1_image.url} > {this.props.data.acf.chef_1_name }</button><img src={this.props.data.acf.chef_1_image.url} alt="chef" className="hidden" /></li>
              <li><button
                    onClick={this.scrollToChef.bind(this)}
                    data-id="chef2"
                    data-text={this.props.data.acf.chef_2_text }
                    data-img={this.props.data.acf.chef_2_image.url} >{this.props.data.acf.chef_2_name }</button><img src={this.props.data.acf.chef_2_image.url} alt="chef" className="hidden" /></li>
              <li><button
                    onClick={this.scrollToChef.bind(this)}
                    data-id="chef4"
                    data-text={this.props.data.acf.chef_4_text }
                    data-img={this.props.data.acf.chef_4_image.url} >{this.props.data.acf.chef_4_name }</button><img src={this.props.data.acf.chef_4_image.url} alt="chef" className="hidden" /></li>
              <li><button
                    onClick={this.scrollToChef.bind(this)}
                    data-id="chef5"
                    data-text={this.props.data.acf.chef_5_text }
                    data-img={this.props.data.acf.chef_5_image.url} >{this.props.data.acf.chef_5_name }</button><img src={this.props.data.acf.chef_5_image.url} alt="chef" className="hidden" /></li>
            </ul>
            <div className="butchery-section">

            </div>
          </div>
          <div className="right chef-bios">
            <div className="chef chef1" data-id="chef1">
              <img src={this.props.data.acf.chef_1_image.url} alt={this.props.data.acf.chef_1_name} />
              <h3>{this.props.data.acf.chef_1_name}</h3>
              <div className="bio" dangerouslySetInnerHTML={{ __html: this.props.data.acf.chef_1_text }} />
            </div>
            <div className="chef chef2" data-id="chef2">
              <img src={this.props.data.acf.chef_2_image.url} alt={this.props.data.acf.chef_2_name} />
              <h3>{this.props.data.acf.chef_2_name}</h3>
              <div className="bio" dangerouslySetInnerHTML={{ __html: this.props.data.acf.chef_2_text }} />
            </div>
            <div className="chef chef4" data-id="chef4">
              <img src={this.props.data.acf.chef_4_image.url} alt={this.props.data.acf.chef_4_name} />
              <h3>{this.props.data.acf.chef_4_name}</h3>
              <div className="bio" dangerouslySetInnerHTML={{ __html: this.props.data.acf.chef_4_text }} />
            </div>
            <div className="chef chef5" data-id="chef5">
              <img src={this.props.data.acf.chef_5_image.url} alt={this.props.data.acf.chef_5_name} />
              <h3>{this.props.data.acf.chef_5_name}</h3>
              <div className="bio" dangerouslySetInnerHTML={{ __html: this.props.data.acf.chef_5_text }} />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default TheChefs;
