import React, { useState } from 'react';
import DataStore from '../flux/stores/DataStore.js';
import Layout from './layout.js';
import {Link} from 'react-router-dom';
// import Footer from './footer.js';
import Footer from './footer-new.js';
import {Helmet} from "react-helmet";
import {injectIntl} from 'react-intl';
// import { useIntl } from 'react-intl';
// import withScrollReveal from 'react-scrollreveal';



function Home(props) {
  const [posterLoaded, setPosterLoaded] = useState(false);
  // const [locale, setLocale] = useState({locale: ''});

  // console.log(locale);
  // console.log(props.intl.locale);

  const home = DataStore.getPageBySlug('home', props.intl.locale);

  console.log(home);

  const trimURL = (url) => {
    const regex = RegExp('([^/]+)(?=[^/]*/?$)');
    url = url.match(regex);
    return url[0];
  }

  const handlePosterLoaded = () => {
    setPosterLoaded(true);
  }

  return (
    <div>
      <Layout layoutClass={`${posterLoaded ? 'poster-loaded' : ''}`}>
        <Helmet>
          <title>HOOCUT</title>
          <meta name="description" content={home.yoast.metadesc} />
          <meta name="keywords" content={home.yoast.metakeywords} />

          <meta property="og:title" content={home.yoast['opengraph-title'] || 'HOOCUT'} />
          <meta name="og:description" content={home.yoast['opengraph-description'] || home.yoast.metadesc} />
          <meta property="og:image" content={home.yoast['opengraph-image'] || home.acf.video_poster.url} />

          <meta name="twitter:title" content={home.yoast['twitter-title'] || 'HOOCUT'} />
          <meta name="twitter:description" content={home.yoast['twitter-description'] || home.yoast.metadesc} />
          <meta name="twitter:image" content={home.yoast['twitter-image'] || home.yoast['opengraph-image'] || home.acf.video_poster.url} />
        </Helmet>
        <img src={home.acf.video_poster.url} onLoad={handlePosterLoaded} alt="Hoocut" className="hidden preloaded" />
        {window.innerWidth > 600 ?
        <video id="home-video" className="home-video video"  loop muted poster={home.acf.video_poster.url}>
          {home.acf.video_mp4 ? <source src={home.acf.video_mp4} type="video/mp4" /> : ''}
          {home.acf.video_webm ? <source src={home.acf.video_webm} type="video/webm" /> : ''}
          {home.acf.video_ogv ? <source src={home.acf.video_ogv} type="video/ogg" /> : ''}
        </video>:<img src={home.acf.video_poster.url} alt="Hoocut" className="poster" />
        }
        <section className="center first-content">
          <div className="sr-item" dangerouslySetInnerHTML={{ __html: home.acf.first_content }} />
        </section>
        <section className="section-links two brown">
          <Link to={`/${trimURL(home.acf.section_1_link.url)}`}>
            <h2 className="sr-item--sequence">{ home.acf.section_1_title }</h2>
            <div to={`/${trimURL(home.acf.section_1_link.url)}`} className="button">{ home.acf.section_1_link.title }</div>
            <img src={home.acf.section_1_background.url} alt="Το Hoocut"/>
          </Link>
          <Link to={`/${trimURL(home.acf.section_2_link.url)}`}>
            <h2 className="sr-item--sequence">{ home.acf.section_2_title }</h2>
            <div to={`/${trimURL(home.acf.section_2_link.url)}`} className="button">{ home.acf.section_2_link.title }</div>
            <img src={home.acf.section_2_background.url} alt="Οι Σεφ"/>
          </Link>
          <Link to={`/${trimURL(home.acf.section_3_link.url)}`}>
            <h2 className="sr-item--sequence">{ home.acf.section_3_title }</h2>
            <div to={`/${trimURL(home.acf.section_3_link.url)}`} className="button">{ home.acf.section_3_link.title }</div>
            <img src={home.acf.section_3_background.url} alt="Το Μενού"/>
          </Link>
          <Link to={`/${trimURL(home.acf.section_4_link.url)}`}>
            <h2 className="sr-item--sequence">{ home.acf.section_4_title }</h2>
            <div to={`/${trimURL(home.acf.section_4_link.url)}`} className="button">{ home.acf.section_4_link.title }</div>
            <img src={home.acf.section_4_background.url} alt="Τα Νέα"/>
          </Link>
        </section>
        <section className="section-links one brown">
          <div>
            <h2>{ home.acf.section_5_title }</h2>
            <Link to={`/${trimURL(home.acf.section_5_link.url)}`} className="button">{ home.acf.section_5_link.title }</Link>
          </div>
        </section>
      </Layout>
      <Footer footerClass="home" nextImage={home.acf.first_image} nextSlug={home.acf.page_slug} nextPage={home.acf.next_page} />
    </div>
  );

}

export default injectIntl(Home, {withRef: true});



// class HomeClass extends React.Component {
//     constructor(props) {
//       super(props);
//
//       console.log(props);
//
//       this.state = {
//         posterLoaded: false,
//         // locale: props.locale
//       }
//     }
//
//     trimURL(url) {
//       const regex = RegExp('([^/]+)(?=[^/]*/?$)');
//       url = url.match(regex);
//       return url[0];
//     }
//
//     handlePosterLoaded() {
//       this.setState({
//         posterLoaded: true
//       })
//     }
//
//     render() {
//       // let allData = DataStore.getAll();
//       let home = DataStore.getPageBySlug('home');
//
//     return (
//       <div>
//         <Layout layoutClass={`${this.state.posterLoaded ? 'poster-loaded' : ''}`}>
//           <Helmet>
//             <title>HOOCUT</title>
//             <meta name="description" content={home.yoast.metadesc} />
//             <meta name="keywords" content={home.yoast.metakeywords} />
//
//             <meta property="og:title" content={home.yoast['opengraph-title'] || 'HOOCUT'} />
//             <meta name="og:description" content={home.yoast['opengraph-description'] || home.yoast.metadesc} />
//             <meta property="og:image" content={home.yoast['opengraph-image'] || home.acf.video_poster.url} />
//
//             <meta name="twitter:title" content={home.yoast['twitter-title'] || 'HOOCUT'} />
//             <meta name="twitter:description" content={home.yoast['twitter-description'] || home.yoast.metadesc} />
//             <meta name="twitter:image" content={home.yoast['twitter-image'] || home.yoast['opengraph-image'] || home.acf.video_poster.url} />
//           </Helmet>
//           <img src={home.acf.video_poster.url} onLoad={this.handlePosterLoaded.bind(this)} alt="Hoocut" className="hidden preloaded" />
//           {window.innerWidth > 600 ?
//           <video id="home-video" className="home-video video"  loop muted poster={home.acf.video_poster.url}>
//             {home.acf.video_mp4 ? <source src={home.acf.video_mp4} type="video/mp4" /> : ''}
//             {home.acf.video_webm ? <source src={home.acf.video_webm} type="video/webm" /> : ''}
//             {home.acf.video_ogv ? <source src={home.acf.video_ogv} type="video/ogg" /> : ''}
//           </video>:<img src={home.acf.video_poster.url} alt="Hoocut" className="poster" />
//           }
//           <section className="center first-content">
//             <div className="sr-item" dangerouslySetInnerHTML={{ __html: home.acf.first_content }} />
//           </section>
//           <section className="section-links two brown">
//             <Link to={`/${this.trimURL(home.acf.section_1_link.url)}`}>
//               <h2 className="sr-item--sequence">{ home.acf.section_1_title }</h2>
//               <div to={`/${this.trimURL(home.acf.section_1_link.url)}`} className="button">{ home.acf.section_1_link.title }</div>
//               <img src={home.acf.section_1_background.url} alt="Το Hoocut"/>
//             </Link>
//             <Link to={`/${this.trimURL(home.acf.section_2_link.url)}`}>
//               <h2 className="sr-item--sequence">{ home.acf.section_2_title }</h2>
//               <div to={`/${this.trimURL(home.acf.section_2_link.url)}`} className="button">{ home.acf.section_2_link.title }</div>
//               <img src={home.acf.section_2_background.url} alt="Οι Σεφ"/>
//             </Link>
//             <Link to={`/${this.trimURL(home.acf.section_3_link.url)}`}>
//               <h2 className="sr-item--sequence">{ home.acf.section_3_title }</h2>
//               <div to={`/${this.trimURL(home.acf.section_3_link.url)}`} className="button">{ home.acf.section_3_link.title }</div>
//               <img src={home.acf.section_3_background.url} alt="Το Μενού"/>
//             </Link>
//             <Link to={`/${this.trimURL(home.acf.section_4_link.url)}`}>
//               <h2 className="sr-item--sequence">{ home.acf.section_4_title }</h2>
//               <div to={`/${this.trimURL(home.acf.section_4_link.url)}`} className="button">{ home.acf.section_4_link.title }</div>
//               <img src={home.acf.section_4_background.url} alt="Τα Νέα"/>
//             </Link>
//           </section>
//           <section className="section-links one brown">
//             <div>
//               <h2>{ home.acf.section_5_title }</h2>
//               <Link to={`/${this.trimURL(home.acf.section_5_link.url)}`} className="button">{ home.acf.section_5_link.title }</Link>
//             </div>
//           </section>
//         </Layout>
//         <Footer footerClass="home" nextImage={home.acf.first_image} nextSlug={home.acf.page_slug} nextPage={home.acf.next_page} />
//       </div>
//     );
//   }
// }


// export default withScrollReveal([
//   {
//     selector: '.sr-item',
//     options: {
//       reset: true,
//       distance: '50px',
//       delay: 200,
//       duration: 1000,
//     },
//   },
//   {
//     selector: '.sr-item--sequence',
//     options: {
//       reset: true,
//       delay: 400,
//       distance: '50px',
//     },
//     interval: 400
//   }
// ])(Home)
