import React from 'react';
import {FormattedMessage} from 'react-intl';


class TheStore extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      image: null
    }
  }

  componentDidMount() {
    this.setState({
      image: this.props.data.acf.bakery_image.url
    });

    window.addEventListener('scroll', this.handleScroll.bind(this));

    // cache menu menu_sections
    this.menuSections = document.querySelectorAll('.section');
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll.bind(this));
  }

  handleScroll() {
    // console.log(window.scrollY, document.querySelectorAll('.section')[1].getBoundingClientRect().top);
    let distFromTop = this.convertRemToPixels(13.75); // 6.25 (header) + 7.5 section top padding
    let imgHalfHeight = ((window.innerWidth - this.convertRemToPixels(10))/ 2 - this.convertRemToPixels(2)) / 3;
    // console.log(document.querySelector('.the-menu .left img').getBoundingClientRect().height);
    // console.log(((window.innerWidth - this.convertRemToPixels(10))/ 2 - this.convertRemToPixels(2)) * 2/3);

    let bakery = document.querySelector('.store-sections .bakery');
    let cutting = document.querySelector('.store-sections .cutting');

    document.querySelectorAll('.store-sections section').forEach((section, i)=>{
      // console.log(window.scrollY - window.innerHeight, bakery.getBoundingClientRect().top);
      // console.log(bakery.getBoundingClientRect().top - window.innerHeight / 2);
      // console.log(cutting.getBoundingClientRect().bottom - window.innerHeight / 2);
      if(bakery.getBoundingClientRect().top - window.innerHeight / 2 + this.convertRemToPixels(5) > 0) {
        bakery.classList.add('active');
      } else if(cutting.getBoundingClientRect().bottom - window.innerHeight / 2 - this.convertRemToPixels(2) < 0) {
        cutting.classList.add('active');
      } else {
        if(section.getBoundingClientRect().top <= distFromTop + imgHalfHeight && section.getBoundingClientRect().bottom > distFromTop + imgHalfHeight) {
          section.classList.add('active');
          if(this.state.image !== section.dataset.img) {
            // console.log(section);
            this.setState({
              image: section.dataset.img
            });
          }
        } else {
          section.classList.remove('active');
        }
      }

    });
  }

  convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

  render() {
    // const menu = this.props.data;

    return (
      <section className={"the-store-2 beige"}>
        <div className="cnt">
          <div className="left store-sections">
            <section
              className="bakery active"
              data-img={this.props.data.acf.bakery_image.url}
            >
              <img src={this.props.data.acf.bakery_image.url} alt="bakery"/>
              <h2><FormattedMessage id="about.bakery" /></h2>
              <div className="text" dangerouslySetInnerHTML={{ __html: this.props.data.acf.bakery_text }} />
            </section>
            <section
              className="grocery"
              data-img={this.props.data.acf.grocery_image.url}
            >
              <img src={this.props.data.acf.grocery_image.url} alt="grocery"/>
              <h2><FormattedMessage id="about.grocery" /></h2>
              <div className="text" dangerouslySetInnerHTML={{ __html: this.props.data.acf.grocery_text }} />
            </section>
            <section
              className="butchery"
              data-img={this.props.data.acf.butchery_image.url}
            >
              <img src={this.props.data.acf.butchery_image.url} alt="butchery"/>
              <h2><FormattedMessage id="about.butchery" /></h2>
              <div className="text" dangerouslySetInnerHTML={{ __html: this.props.data.acf.butchery_text }} />
            </section>
            <section
              className="barbeque"
              data-img={this.props.data.acf.barbeque_image.url}
            >
              <img src={this.props.data.acf.barbeque_image.url} alt="barbeque"/>
              <h2><FormattedMessage id="about.rotisserie" /></h2>
              <div className="text" dangerouslySetInnerHTML={{ __html: this.props.data.acf.barbeque_text }} />
            </section>
            <section
              className="cutting"
              data-img={this.props.data.acf.cutting_image.url}
            >
              <img src={this.props.data.acf.cutting_image.url} alt="cutting"/>
              <h2><FormattedMessage id="about.cuts" /></h2>
              <div className="text" dangerouslySetInnerHTML={{ __html: this.props.data.acf.cutting_text }} />
            </section>
          </div>
          <div className="right sticy">
            <img src={this.state.image} alt="next page" />
          </div>
        </div>

      </section>
    );
  }
}

export default TheStore;
