import React from 'react';
import Header from './header.js';
import { withRouter } from 'react-router';
import {Helmet} from "react-helmet";

class Layout extends React.Component {
  componentDidMount() {
    setTimeout(()=>{
      document.body.classList.remove('hide-header');
    }, 1000);
  }

  render() {
    return (
      <div className={`content ${this.props.location.pathname === '/' ? 'home' : this.props.location.pathname.substring(1)} ${this.props.layoutClass ? this.props.layoutClass : ''}`}>
        <Helmet>
          <link rel="canonical" href={`https://hoocut.com${this.props.location.pathname}`} />
          <meta property="og:url" content={`https://hoocut.com${this.props.location.pathname}`} />
        </Helmet>
        <Header {... this.props} />
        <main role="main" className={`main ${this.props.mainClass ? this.props.mainClass : ''}`}>
            { this.props.children }
        </main>
      </div>
    );
  }
}

export default withRouter(Layout);
