import React from 'react';
import DataStore from '../flux/stores/DataStore.js';
import Layout from './layout.js';
// import Footer from './footer.js';
import Footer from './footer-new.js';
import TheMenu from './the-menu.js';
import {Helmet} from "react-helmet";
import {injectIntl} from 'react-intl';



class Menu extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    let menu = DataStore.getPageBySlug('menu', this.props.intl.locale);

    return (
      <div>
        <Layout>
          <Helmet>
            <title>HOOCUT - {menu.title.rendered}</title>
            <meta name="description" content={menu.yoast.metadesc} />
            <meta name="keywords" content={menu.yoast.metakeywords} />

            <meta property="og:title" content={menu.yoast['opengraph-title'] || 'HOOCUT - Menu'} />
            <meta name="og:description" content={menu.yoast['opengraph-description'] || menu.yoast.metadesc} />
            <meta property="og:image" content={menu.yoast['opengraph-image'] || menu.acf.intro_background.url} />

            <meta name="twitter:title" content={menu.yoast['twitter-title'] || 'HOOCUT - Menu'} />
            <meta name="twitter:description" content={menu.yoast['twitter-description'] || menu.yoast.metadesc} />
            <meta name="twitter:image" content={menu.yoast['twitter-image'] || menu.yoast['opengraph-image'] || menu.acf.intro_background.url} />
          </Helmet>
          <section id="intro-section" className="intro-section"  style={{
            backgroundImage: 'url(' + menu.acf.intro_background.url + ')'
          }}>
            <h1>{menu.acf.intro_tagline}</h1>
          </section>
          <section className="center first-content">
            <div dangerouslySetInnerHTML={{ __html: menu.acf.first_content }} />
          </section>
          <TheMenu {...this.props} data={menu.acf.menu_sections} />
        </Layout>
        <Footer nextImage={menu.acf.first_image} nextSlug={menu.acf.page_slug} nextPage={menu.acf.next_page} />
      </div>
    );
  }
}

export default injectIntl(Menu, {withRef: true});
