import React from 'react'; //, { Component }

class FooterMapPin extends React.Component {
  render() {
      return (
        <svg className="footer-map-pin" width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg"><circle cx="25" cy="25" r="25" fill="#4A3E3F"/><path d="M25 26C25.6187 26 26.1486 25.7844 26.5896 25.3532C27.0299 24.9227 27.25 24.405 27.25 23.8C27.25 23.195 27.0299 22.6769 26.5896 22.2457C26.1486 21.8152 25.6187 21.6 25 21.6C24.3813 21.6 23.8518 21.8152 23.4115 22.2457C22.9705 22.6769 22.75 23.195 22.75 23.8C22.75 24.405 22.9705 24.9227 23.4115 25.3532C23.8518 25.7844 24.3813 26 25 26ZM25 37C21.9812 34.4883 19.7267 32.1552 18.2365 30.0007C16.7455 27.8469 16 25.8533 16 24.02C16 21.27 16.9049 19.0792 18.7146 17.4475C20.5236 15.8158 22.6188 15 25 15C27.3812 15 29.4764 15.8158 31.2854 17.4475C33.0951 19.0792 34 21.27 34 24.02C34 25.8533 33.2549 27.8469 31.7646 30.0007C30.2736 32.1552 28.0188 34.4883 25 37Z" fill="#E7DBC8"/></svg>
      );
  }
}

export default FooterMapPin;
