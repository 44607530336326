import React from 'react';
import DataStore from '../flux/stores/DataStore.js';
import Layout from './layout.js';
// import Footer from './footer.js';
import Footer from './footer-new.js';
import TheStore from './the-store.js';
import {Helmet} from "react-helmet";
import {injectIntl} from 'react-intl';


class About extends React.Component {
  render() {
    let about = DataStore.getPageBySlug('hoocut', this.props.intl.locale);

    return (
      <div>
        <Layout>
          <Helmet>
            <title>HOOCUT - {about.title.rendered}</title>
            <meta name="description" content={about.yoast.metadesc} />
            <meta name="keywords" content={about.yoast.metakeywords} />

            <meta property="og:title" content={about.yoast['opengraph-title'] || 'HOOCUT - About'} />
            <meta name="og:description" content={about.yoast['opengraph-description'] || about.yoast.metadesc} />
            <meta property="og:image" content={about.yoast['opengraph-image'] || about.acf.intro_background.url} />

            <meta name="twitter:title" content={about.yoast['twitter-title'] || 'HOOCUT - About'} />
            <meta name="twitter:description" content={about.yoast['twitter-description'] || about.yoast.metadesc} />
            <meta name="twitter:image" content={about.yoast['twitter-image'] || about.yoast['opengraph-image'] || about.acf.intro_background.url} />
          </Helmet>
          <section id="intro-section" className="intro-section"  style={{
            backgroundImage: 'url(' + about.acf.intro_background.url + ')'
          }}>
            <h1>{about.acf.intro_tagline}</h1>
          </section>
          <section className="center first-content">
            <div dangerouslySetInnerHTML={{ __html: about.acf.first_content }} />
          </section>
          <section className="back-to-basics">
            <img src={about.acf.basics_image.url} alt="back to basics"/>
            <div className="title">{about.acf.basics_title}</div>
          </section>
          <TheStore {...this.props} data={about} />
        </Layout>
        <Footer nextImage={about.acf.first_image} nextSlug={about.acf.page_slug} nextPage={about.acf.next_page} />
      </div>
    );
  }
}

export default injectIntl(About, {withRef: true});;
