import React from 'react';
import DataStore from '../flux/stores/DataStore.js';
import Instafeed from 'react-instafeed';
import {scrollItV} from './helper-lib.js';
import Swipe from 'react-easy-swipe';
import InstagramEmbed from 'react-instagram-embed';
import InstagramFeed  from 'react-ig-feed';
import 'react-ig-feed/dist/index.css';
import Feed from "react-instagram-authless-feed";

class InstaFeed extends React.Component {
  constructor(props) {
    super(props);

    this.instaPrev = this.instaPrev.bind(this);
    this.instaNext = this.instaNext.bind(this);
    this.swipeLeft = this.onSwipeLeft.bind(this);
    this.swipeRight = this.onSwipeRight.bind(this);

    // this.scrollToPage = this.scrollToPage.bind(this);
    // this.touchStartVar = 0;

  }

  componentDidMount() {
    window.addEventListener('resize', this.resetSlider.bind(this));
    window.addEventListener('touchstart', this.touchStart);
    window.addEventListener('touchmove', this.preventTouch, {passive: false});
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resetSlider.bind(this));
    window.removeEventListener('touchstart', this.touchStart);
    window.removeEventListener('touchmove', this.preventTouch, {passive: false});
  }

  touchStart(e){
    this.firstClientX = e.touches[0].clientX;
    this.firstClientY = e.touches[0].clientY;
  }

  preventTouch(e){
    const minValue = 5; // threshold

    this.clientX = e.touches[0].clientX - this.firstClientX;
    this.clientY = e.touches[0].clientY - this.firstClientY;

    // Vertical scrolling does not work when you start swiping horizontally.
    if(Math.abs(this.clientX) > minValue){
        e.preventDefault();
        e.returnValue = false;
        return false;
    }
  }

  instaPrev() {
    this.scrollToPage('prev');
  }

  instaNext() {
    this.scrollToPage('next');
  }

  // touchStart(e) {
  //   console.log(e);
  // }
  //
  // onSwipeStart(event) {
  //   console.log('Start swiping...');
  // }
  //
  // onSwipeMove(position, event) {
  //   console.log(`Moved ${position.x} pixels horizontally`);
  //   console.log(`Moved ${position.y} pixels vertically`);
  // }
  //
  // onSwipeEnd(event) {
  //   console.log('End swiping...');
  // }

  onSwipeLeft(event) {
    this.scrollToPage('next');
  }

  onSwipeRight(event) {
    this.scrollToPage('prev');
  }

  scrollToPage(direction) {
    // console.log(`going to ${direction}`);
    const cnt = document.querySelector('.insta-cnt');
    const firstElement = cnt.querySelector('.insta-item');
    const firstElementWidth = firstElement.getBoundingClientRect().width;
    const elementScrollWidth = window.innerWidth > 600 ? firstElementWidth + this.convertRemToPixels(3.75) : firstElementWidth + this.convertRemToPixels(.75);
    // console.log(window.innerWidth);
    // console.log(`firstElementWidth is ${firstElementWidth}`);
    // console.log(`elementScrollWidth is ${elementScrollWidth}`);
    // const scrollDistance = Math.round(cnt.getBoundingClientRect().width);
    // let scrollDistance = cnt.offsetWidth;
    let scrollDistance;
    let scrollFactor = window.innerWidth <= 600 ? 1 : 3;
    const cntWidth = cnt.getBoundingClientRect().width;
    const cntScrollWidth = cnt.scrollWidth;

    // console.log(`the distance is ${scrollDistance}`);
    // console.log(cnt.scrollWidth, cnt.offsetWidth, cnt.scrollLeft);
    if(direction === 'next') {
      // console.log(cnt.scrollLeft + scrollFactor * elementScrollWidth, cntScrollWidth)
      if(cnt.scrollLeft + (2 * scrollFactor) * elementScrollWidth < cntScrollWidth) {
        scrollDistance = cnt.scrollLeft + scrollFactor * elementScrollWidth;
        // console.log(`scrollDistance is ${scrollDistance}`);
        // console.log('GO TO NEXT PAGE');
      } else if(cnt.scrollLeft + scrollFactor * elementScrollWidth <= cntScrollWidth - 60) {
        scrollDistance = cntScrollWidth - cntWidth;
        // console.log('GO TO LAST PAGE');
        if(window.innerWidth <= 600) {
          scrollDistance = 0;
        }
      } else {
        // console.log('REACHED THE END, GO TO FIRST');
        scrollDistance = 0;
      }
    } else if(direction === 'prev') {
      // console.log(`insed PREV scrollLeft is ${cnt.scrollLeft} and ${scrollFactor} items width is ${scrollFactor * elementScrollWidth}`);
      if(cnt.scrollLeft === 0) {
        // console.log('GO TO END');
        scrollDistance = cntScrollWidth - cntWidth;
      } else if(cnt.scrollLeft >= Math.floor(scrollFactor * elementScrollWidth) ) {
        // console.log('GO TO PREV PAGE');
        scrollDistance = cnt.scrollLeft - scrollFactor * elementScrollWidth;
      } else {
        scrollDistance = 0;
      }
    }

    // const cnt = window;
    scrollItV(
      cnt,
      scrollDistance, // 200, // document.body.scrollHeight - window.innerHeight,
      300,
      'easeOutQuad',
      'horizontal'
    );
  }

  resetSlider() {
    const cnt = document.querySelector('.insta-cnt');
    scrollItV(
      cnt,
      0,
      300,
      'easeOutQuad',
      'horizontal'
    );
  }

  convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

  render() {
    let options = DataStore.getPageBySlug('options');
    const instafeedTarget = 'instafeed';

    return (
      <section className="instagram-feed">
        <Feed userName="hoocut" className="Feed" classNameLoading="Loading" limit="3"/>

        <header className="insta-header">
          <h2>Instagram</h2>
          <a href={options.acf.instagram.url} className="button instagram-link"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26.58"><path  d="M-381.63-473.42h-10.74A7.62,7.62,0,0,1-400-481v-11.4a7.62,7.62,0,0,1,7.63-7.59h10.74a7.62,7.62,0,0,1,7.63,7.59V-481A7.62,7.62,0,0,1-381.63-473.42ZM-392.37-498a5.66,5.66,0,0,0-5.67,5.64V-481a5.66,5.66,0,0,0,5.67,5.64h10.74A5.66,5.66,0,0,0-376-481v-11.4a5.66,5.66,0,0,0-5.67-5.64Z" transform="translate(400 500)"/><path d="M-387-479.69a7.09,7.09,0,0,1-7.1-7.06,7.12,7.12,0,0,1,2.18-5.12,6.83,6.83,0,0,1,4.95-1.94,7.42,7.42,0,0,1,5.31,2.14,6.86,6.86,0,0,1,1.76,4.95h0a7.23,7.23,0,0,1-2.1,5A7,7,0,0,1-387-479.69Zm-.16-11.74a4.41,4.41,0,0,0-3.1,1.27,4.77,4.77,0,0,0-1.45,3.41,4.7,4.7,0,0,0,4.71,4.68,4.73,4.73,0,0,0,4.71-4.7,4.49,4.49,0,0,0-1.12-3.27,5,5,0,0,0-3.62-1.39Z" transform="translate(400 500)"/><ellipse cx="20.27" cy="5.99" rx="1.66" ry="1.65"/></svg> <span>@hoocut</span></a>
        </header>
        <Swipe
        onSwipeLeft={this.onSwipeLeft.bind(this)}
        onSwipeRight={this.onSwipeRight.bind(this)}
        className="insta-outer-cnt">
          <div className="insta-cnt">
            <div id={instafeedTarget} className="instafeed">


            </div>
            <button
              className="insta-prev-next insta-prev"
              onClick={this.instaPrev}
            ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.06 19.63"><path d="M13.06,0,6.53,9.79l6.53,9.84H6.72L0,9.79,6.72,0Z"/></svg></button>
            <button
              className="insta-prev-next insta-next"
              onClick={this.instaNext}
            ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.06 19.63"><path d="M0,19.63,6.53,9.84,0,0H6.34l6.72,9.84L6.34,19.63Z"/></svg></button>
          </div>
      </Swipe>

      </section>
    );
  }
}

export default InstaFeed;



// <Instafeed
//   limit='10'
//   ref='instafeed'
//   resolution='standard_resolution'
//   sortBy='most-recent'
//   target={instafeedTarget}
//   template='<a href="{{link}}" class="insta-item" target="_blank"><div class="insta-img"><img src="{{image}}" /></div><div class="insta-likes"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24.56 22"><path d="M17.66,1.06a5.28,5.28,0,0,1,2.9.88,7.18,7.18,0,0,1,2.17,9.17c-1.48,2.86-8.18,8.18-10.2,9.74a.45.45,0,0,1-.25.09.42.42,0,0,1-.25-.09C10,19.29,3.31,14,1.83,11.11A7.17,7.17,0,0,1,4,1.94a5.24,5.24,0,0,1,2.89-.88,5.64,5.64,0,0,1,4.57,2.46l.82,1.15.82-1.15a5.63,5.63,0,0,1,4.56-2.46m0-1.06a6.66,6.66,0,0,0-5.38,2.89A6.68,6.68,0,0,0,6.89,0,6.25,6.25,0,0,0,3.45,1,8.26,8.26,0,0,0,.93,11.61c1.55,3,8.12,8.26,10.49,10.09a1.39,1.39,0,0,0,.86.3,1.41,1.41,0,0,0,.86-.3c2.37-1.83,8.94-7.07,10.49-10.09A8.25,8.25,0,0,0,21.1,1a6.23,6.23,0,0,0-3.44-1Z"/></svg>{{likes}} likes</div><div class="insta-caption"><div class="inner">{{caption}}</div></div></a>'
//   userId='6694381853'
//   // clientId='66ebf5609e7a46b69bd3eeca1c0e30f4'
//   accessToken='6694381853.44a5744.b148fb3ebb0f4c999710ad6b0ec2084d'
// />
