import React from 'react';
import PropTypes from "prop-types";
import DataStore from '../flux/stores/DataStore.js';
import {Link} from 'react-router-dom';
import FbIcon from '../../svg/fb-icon.js';
import InstagramIcon from '../../svg/instagram-icon.js';

import FooterEmailIcon from '../../svg/footer-email-icon.js';
import FooterInstagramIcon from '../../svg/footer-instagram-icon.js';
import FooterFbIcon from '../../svg/footer-fb-icon.js';
import FooterMapPin from '../../svg/footer-map-pin.js';

import HoocutMap from './hoocut-map.js';
// import HoocutMapTest from './hoocut-map-testing.js';
import {scrollIt} from './helper-lib.js';
import { withRouter } from 'react-router';
import {injectIntl, FormattedMessage} from 'react-intl';
// import {injectIntl} from 'react-intl'


class Footer extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);

    console.log(props);

    this.state = {
      nextPageBg: null
    }
  }

  componentDidMount() {
    this.chooseBg();

    window.addEventListener('resize', this.chooseBg.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.chooseBg.bind(this));
  }

  chooseBg() {
    let nextPage = null;
    if(this.props.nextPage) {
      nextPage = DataStore.getPageBySlug(this.props.nextPage.post_name);

      console.log('next page is');
      console.log(nextPage.acf);
    }

    if(window.innerWidth > 900) {
      this.setState({
        nextPageBg: nextPage.acf.intro_background.url
      });
    } else {
      if(nextPage.acf.intro_background_mobile) {
        this.setState({
          nextPageBg: nextPage.acf.intro_background_mobile.url
        });
      } else {
        this.setState({
          nextPageBg: nextPage.acf.intro_background.url
        });
      }
    }
  }

  goToNextSection() {
    let nextSection = document.querySelector('#next-section');
    nextSection.classList.add('active');
    document.body.classList.add('hide-header');

    const cnt = window;
    scrollIt(
      cnt,
      document.body.scrollHeight - window.innerHeight,
      300,
      'easeOutQuad'
    );
  }

  parseCoordinates(list) {
    let coordArray = [],
        lines = list.split('\n');
    coordArray = lines;
    lines.forEach((line, i)=>{
      let coords = line.replace(/ /g,'').replace(/,\s*$/, "").split(',');
      coordArray[i] = {
        lat: parseFloat(coords[0]),
        lng: parseFloat(coords[1])
      };
    });

    return coordArray;
  }

  render() {
    console.log(`locale in footer is ${this.props.intl.locale}, rendering`);
    let options = DataStore.getPageBySlug('options', this.props.intl.locale || 'el');
    let currentYear = new Date().getFullYear();
    let nextPage = null;
    if(this.props.nextPage) {
      nextPage = DataStore.getPageBySlug(this.props.nextPage.post_name, this.props.intl.locale);
    }

    console.log(nextPage);

    const { history } = this.props;
    const lat = options.acf.map_latitude ||  '37.976857';
    const long = options.acf.map_longitude || '23.728079';
    const lat2 = options.acf.map_latitude_2 ||  '37.9438976';
    const long2 = options.acf.map_longitude_2 || '23.7124229';
    const lat3 = options.acf.map_latitude_3 ||  '38.0128044';
    const long3 = options.acf.map_longitude_3 || '23.6924572';
    const marker_url = options.acf.marker_url;
    const marker_url_2 = options.acf.marker_url_2;
    const marker_url_3 = options.acf.marker_url_3;
    const coordinates = this.parseCoordinates(options.acf.coordinate_list);

    const stores = options.acf.stores || null;
    console.log(typeof stores);
    // console.log([...stores]);

    return (
      <footer className={`site-footer ${this.props.footerClass} ${nextPage ? 'has-next': ''}`}>

        <div className="footer-stores">
          {[...stores].map((store, i)=>{
            return <div className="store" key={i}>
              <div className="thumb-cnt"><img className="thumb" src={store.image.url} /></div>
              <h3 className="name">{store.name}</h3>
              <div className="store-section">
              { store.address ? <h4><FormattedMessage id="footer.address" /></h4> : '' }
              { store.address ? <div className="address">{store.address}</div> : '' }
              </div>
              <div className="store-section">
              { store.telephone ? <h4><FormattedMessage id="footer.phone" /></h4> : '' }
              { store.telephone ? <div className="telephone"><a href={store.telephone.url}>{store.telephone.title}</a></div> : '' }
              </div>
              <div className="store-section">
              { store.hours ? <h4><FormattedMessage id="footer.storeHours" /></h4> : '' }
              { store.hours ? <div className="hours"><div dangerouslySetInnerHTML={{ __html: store.hours }} /></div> : '' }
              </div>
              <p><a href={store.map_link} className="map-link" target="_blank"><FooterMapPin /> <FormattedMessage id="footer.map" /></a></p>

            </div>
          })}


        </div>
        <div className="footer-contact sr-item">
          { options.acf.email.url ? <p><a href={options.acf.email.url}><FooterEmailIcon /><br/>{options.acf.email.title}</a></p> : '' }
          <p><a href={options.acf.instagram.url}><FooterInstagramIcon /><br/>{options.acf.instagram.title}</a></p>
          <p><a href={options.acf.facebook.url}><FooterFbIcon /><br/>{options.acf.facebook.title}</a></p>

        </div>
        <div className="credits">
          <div className="credits-cnt">
          <span className="copy">&copy; HOOCUT {currentYear} • </span><Link to='/privacy-policy' className="link pp-lnk"><FormattedMessage id="pages.privacyPolicy" /> • </Link><Link to='/cookies-policy' className="link cp-lnk"><FormattedMessage id="pages.cookiePolicy" /></Link> <span className="credit">Website by <a href="https://nowhere.studio" target="_blank" rel="noopener noreferrer">Nowhere</a></span>
          </div>
        </div>
        {nextPage ?
        <div id="next-section" className="next-section"
        onClick={() => {
          this.goToNextSection();
          setTimeout(() => {
            history.push({
              pathname: this.props.nextSlug,
              state: {transition: window.innerWidth > 600 ? 'slide' : 'slide-mobile' , duration: 500}
            });
          }, 300);

        }}
        >
          <div className="cnt">
            <img src={this.state.nextPageBg} alt={`go to ${nextPage.title.rendered}`} />
          </div>
          <div className="title">
            <div className="nextText"><FormattedMessage id="next.next" /></div>
            <h1 dangerouslySetInnerHTML={{__html:nextPage.acf.intro_tagline}} />
          </div>
        </div>:null
        }
      </footer>
    );
  }
}

export default injectIntl(withRouter(Footer), {withRef: true});
