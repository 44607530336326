import React from 'react'; //, { Component }

class FooterFbIcon extends React.Component {
  render() {
      return (
        <svg className="footer-fb-icon-svg" width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M34.005 0H1.995C1.46711 0.00392502 0.961947 0.215372 0.58866 0.58866C0.215372 0.961947 0.00392502 1.46711 0 1.995V34.005C0.00392502 34.5329 0.215372 35.0381 0.58866 35.4113C0.961947 35.7846 1.46711 35.9961 1.995 36H19.23V22.08H14.55V16.635H19.23V12.63C19.23 7.98 22.065 5.445 26.235 5.445C27.63 5.445 29.025 5.445 30.42 5.655V10.5H27.555C25.29 10.5 24.855 11.58 24.855 13.155V16.62H30.255L29.55 22.065H24.855V36H34.005C34.5329 35.9961 35.0381 35.7846 35.4113 35.4113C35.7846 35.0381 35.9961 34.5329 36 34.005V1.995C35.9961 1.46711 35.7846 0.961947 35.4113 0.58866C35.0381 0.215372 34.5329 0.00392502 34.005 0Z" fill="#E7DBC8"/></svg>
      );
  }
}

export default FooterFbIcon;
