import React from 'react';
import DataStore from '../flux/stores/DataStore.js';
import Layout from './layout.js';
import {withRouter} from 'react-router';
import {Helmet} from "react-helmet";
import {injectIntl} from 'react-intl';


class Terms extends React.Component {
  render(){
    let terms = DataStore.getPageBySlug('privacy-policy', this.props.intl.locale);
    const {history} = this.props;
    const termsText = terms.content.rendered;

    function goBack() {
      history.goBack();
    }

    return (
      <div>
        <Layout>
          <Helmet>
            <title>HOOCUT - {terms.title.rendered}</title>
            <meta name="description" content={terms.yoast.metadesc} />
            <meta name="keywords" content={terms.yoast.metakeywords} />

            <meta property="og:title" content={terms.yoast['opengraph-title'] || 'HOOCUT - Privacy Policy'} />
            <meta name="og:description" content={terms.yoast['opengraph-description'] || terms.yoast.metadesc} />
            <meta property="og:image" content={terms.yoast['opengraph-image']} />

            <meta name="twitter:title" content={terms.yoast['twitter-title'] || 'HOOCUT - Privacy Policy'} />
            <meta name="twitter:description" content={terms.yoast['twitter-description'] || terms.yoast.metadesc} />
            <meta name="twitter:image" content={terms.yoast['twitter-image'] || terms.yoast['opengraph-image']} />
          </Helmet>
          <div className="cnt">
            <div className="left">
              <h1 dangerouslySetInnerHTML={{__html: terms.title.rendered}} />
            </div>
            <div className="right">
              <div className="terms-text" dangerouslySetInnerHTML={{ __html: termsText }} />
              <button className='button go-back brown' onClick={goBack}>Go Back</button>
            </div>
          </div>
        </Layout>
      </div>
    );
  }
}

export default injectIntl(withRouter(Terms), {withRef: true});
