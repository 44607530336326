import React from 'react';
import Layout from './layout.js';
import {Link} from 'react-router-dom';


class NoMatch extends React.Component {
  // componentDidMount() {
  //   document.body.classList.add('page-not-found');
  // }
  //
  // componentWillUnmount() {
  //   document.body.classList.remove('page-not-found');
  // }

  render() {
    return (
      <div>
        <Layout layoutClass="page-not-found">
          <div className="cnt not-found-cnt">
            <h1>404</h1>
            <div className="not-found">
              The page you were looking for<br />
              was not found
            </div>
            <Link to="/" className="button brown">Homepage</Link>
          </div>
        </Layout>
      </div>
    );
  }
}

export default NoMatch;
