import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import '../styles/App.css';
import Home from './components/home.js';
import About from './components/about.js';
import Chefs from './components/chefs.js';
import Menu from './components/menu.js';
import StayTuned from './components/stay-tuned.js';
import Terms from './components/terms.js';
import Cookies from './components/cookies.js';
import NoMatch from './components/no-match.js';
import { withRouter } from 'react-router';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import Wrapper from "./components/wrapper";
// import { useIntl } from 'react-intl';

// import { addLocaleData } from 'react-intl';
// import en from 'react-intl/locale-data/en';
// import el from 'react-intl/locale-data/el';

// addLocaleData([...en, ...el]);




//
// let messagesEN = {
//     "menu.about": "About",
//     "menu.projects":"Projects",
//     "menu.people":"People",
//     "menu.contact":"Contact",
//     "menu.greece":"Greece",
//     "menu.aus":"Australia",
//     "project.type_of_building":"Type of Building",
//     "project.service":"Service",
//     "project.surface":"Surface",
//     "project.levels":"Levels",
//     "project.year":"Year",
//     "people.title":"People",
//     "contact.title":"Contact",
//     "header.order":"Online Order",
// }
//
// let messagesEL = {
//     "menu.about": "Σχετικα",
//     "menu.projects":"Εργα",
//     "menu.people":"Ανθρωποι",
//     "menu.contact":"Επικοινωνια",
//     "menu.greece":"Ελλαδα",
//     "menu.aus":"Αυστραλια",
//     "project.type_of_building":"Τυπος Κτιριου",
//     "project.service":"Υπηρεσια",
//     "project.surface":"Επιφανεια",
//     "project.levels":"Επιπεδα",
//     "project.year":"Χρονολογια",
//     "people.title":"Ανθρωποι",
//     "contact.title":"Επικοινωνια",
//     "header.order":"Online Παραγγελια",
// }
//
// let i18nConfig = {
//   locale: 'el',
//   messages: messagesEL
// };





// const config = { attributes: true, childList: false, subtree: false };
// // Callback function to execute when mutations are observed
// const callback = function(mutationsList, observer) {
//     // Use traditional 'for loops' for IE 11
//     for(const mutation of mutationsList) {
//       console.log(mutation);
//         if (mutation.type === 'childList') {
//             console.log('A child node has been added or removed.');
//         }
//         else if (mutation.type === 'attributes') {
//             console.log('The ' + mutation.attributeName + ' attribute was modified.');
//         }
//     }
// };
//
// const observer = new MutationObserver(callback);
//
// observer.observe(document.documentElement, config);


const TheApp = withRouter(({location, ...props}) => {
  // const intl = useIntl();
  //
  // console.log(`locale is ${intl.locale}`);
  // const self = this;
  // const [customLocale, setcustomLocale] = useState('test-localister');

  // console.log('TheApp running');
  //
  // window.addEventListener('localeChanged', () => {
  //   console.log('listened to localeChaged event');
  //   // self.setState({locale: document.documentElement.lang});
  //   setcustomLocale('new-test-locale')
  // });

  return <Wrapper>
    <div className="App">
      <TransitionGroup
        childFactory={child => React.cloneElement(
          child,
          {
            // key:location.key,
            classNames: (location.state !== undefined && location.state.transition !== undefined ? location.state.transition : 'fade'),
            timeout: (location.state !== undefined && location.state.timeout !== undefined ? location.state.timeout : {enter: 1600, exit: 800}),
            onEnter: (location.state !== undefined && (location.state.transition === 'slide' || location.state.transition === 'slide-mobile') ? ()=>{
              const uA = navigator.userAgent;
              const vendor = navigator.vendor;
              if (/Safari/i.test(uA) && /Apple Computer/.test(vendor) && !/Mobi|Android/i.test(uA)) {
                //Desktop Safari
                window.scrollTo(0, 0);
                console.log('desktop safari scroll');
              }

            } : ()=>{console.log('this is the fade transition');})
          }
        )}
      >
        <CSSTransition
          key={location.key}
          classNames='fade'
          timeout={{
            enter: 1600,
            exit: 800
          }}
          onExited={()=>{window.scrollTo(0, 0);}}
          // onEnter={()=>{}}
        >
          <Switch location={location} locale="test-locale">
            <Route exact path='/' render={() => <Home />} />
            <Route exact path='/hoocut' component={About} />
            <Route exact path='/chefs' component={Chefs} />
            <Route exact path='/menu' component={Menu} />
            <Route exact path='/news' component={StayTuned} />
            <Route exact path='/privacy-policy' component={Terms} />
            <Route exact path='/cookies-policy' component={Cookies} />
            <Route component={NoMatch} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </div>
  </Wrapper>
}
)

class App extends Component {
  componentDidMount() {
    window.addEventListener('resize', this.checkEnvironment.bind(this));
    window.addEventListener('orientationchange', this.checkEnvironment.bind(this));
    window.dispatchEvent(new Event('resize'));
    // window.addEventListener('scroll', this.checkScroll.bind(this));

    // disable browser back auto scroll to where it was
    if ('scrollRestoration' in window.history) {
      // Back off, browser, I got this...
      window.history.scrollRestoration = 'manual';
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkEnvironment.bind(this));
    window.removeEventListener('orientationchange', this.checkEnvironment.bind(this));
    // window.removeEventListener('scroll', this.checkScroll.bind(this));
  }

  checkScrollbar() {
    // check for scrollbarWidth
    // Create the measurement node
    var scrollDiv = document.createElement("div");
    scrollDiv.className = "scrollbar-measure";
    document.body.appendChild(scrollDiv);

    // Get the scrollbar width
    var scrollbarWidth = scrollDiv.offsetWidth - scrollDiv.clientWidth;

    // Delete the DIV and set :root variable
    document.body.removeChild(scrollDiv);
    document.documentElement.style.setProperty('--scrollbar-width', `${scrollbarWidth}px`);
  }

  // checkScroll(e) {
  //   window.scrollY > 100 ? document.body.classList.add('content-scrolled') : document.body.classList.remove('content-scrolled');
  // }

  checkEnvironment() {
    // console.log(`window.screen.availHeight ${window.screen.availHeight}`);
    // console.log(`window.screen.availWidth ${window.innerHeight}`);

    setTimeout(() => {
      if( (Math.abs(window.orientation) === 90 ? window.screen.availWidth : window.screen.availHeight) - window.innerHeight > 50 ) {
        document.body.classList.add('bottom-bar-present');
      } else {
        document.body.classList.remove('bottom-bar-present');
      }
    }, 200);
    this.checkScrollbar();
  }

  render() {
    return (
      <TheApp />
    );
  }
}

export default App;
