import alt          from '../alt/alt.js';
import DataActions  from '../actions/DataActions.js';

class DataStore {
    constructor() {
        this.data = {};

        this.bindListeners({
            // Listen to the getSuccess() in DataActions.js
            handleSuccess: DataActions.GET_SUCCESS
        });

        this.exportPublicMethods({
            getAll:         this.getAll,
            getAllPages:    this.getAllPages,
            getAllPosts:    this.getAllPosts,
            getPageBySlug:  this.getPageBySlug,
            getOptions:     this.getOptions
        });
    }

    // Store data returned by getSuccess() in DataActions.js
    handleSuccess(data) {
        this.setState({ data });
    }

    // Returns all pages and posts
    getAll() {
        return this.getState().data;
    }

    // Returns all Pages
    getAllPages() {
        return this.getState().data.pages;
    }

    // Returns all Posts
    getAllPosts(locale) {
      let posts;

      if(locale === 'en-US' || locale === 'en') {
        posts = this.getState().data.postsEn;
      } else {
        posts = this.getState().data.posts;
      }

      return posts
    }

    // Returns Options
    getOptions() {
      // console.log('getting the options');
      // console.log(this.getState().data);
        return this.getState().data.options;
    }

    // Returns a Page by provided slug
    getPageBySlug(slug, locale){
        let pages;

        if(locale === 'en-US' || locale === 'en') {
          pages = this.getState().data.pagesEn;
          // console.log('pagesEn is');
          // console.log(pages);
        } else {
          pages = this.getState().data.pages;
        }

        console.log(`locale in DataStore is ${locale}`);
        console.log(pages);


        return pages[Object.keys(pages).find((page, i) => {
            return pages[page].slug === slug;
        })] || {};
    }

}

export default alt.createStore(DataStore, 'DataStore');
