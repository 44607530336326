import React from 'react';

class TheMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      image: null
    }
  }

  componentDidMount() {
    this.setState({
      image: this.props.data[0].image
    });

    window.addEventListener('scroll', this.handleScroll.bind(this));

    // cache menu menu_sections
    this.menuSections = document.querySelectorAll('.section');
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll.bind(this));
  }

  handleScroll() {
    // console.log(window.scrollY, document.querySelectorAll('.section')[1].getBoundingClientRect().top);
    let distFromTop = this.convertRemToPixels(13.75); // 6.25 (header) + 7.5 section top padding
    let imgHalfHeight = ((window.innerWidth - this.convertRemToPixels(10))/ 2 - this.convertRemToPixels(2)) / 3;
    // console.log(document.querySelector('.the-menu .left img').getBoundingClientRect().height);
    // console.log(((window.innerWidth - this.convertRemToPixels(10))/ 2 - this.convertRemToPixels(2)) * 2/3);

    document.querySelectorAll('.section').forEach((section, i)=>{
      if(section.getBoundingClientRect().top <= distFromTop + imgHalfHeight && section.getBoundingClientRect().bottom > distFromTop + imgHalfHeight) {
        section.classList.add('active');
        if(this.state.image !== section.dataset.img) {
          this.setState({
            image: section.dataset.img
          });
        }
      }
    });
  }

  convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

  render() {
    // console.log(this.props.data);
    const menu = this.props.data;

    const Section = (section) => {
      // console.log(section);
      return <div className="section" data-img={section.data.image}>
        <h2>{section.data.title}</h2>
        <ul>
          {section.data.menu_items.map((item, i)=>{
            return <li key={i}>
              <div className="name">{item.name}{item.size ? <span className="size"> {item.size}</span>: null}</div>
              <div className="ingredients">{item.ingredients}</div>
            </li>
          })}
        </ul>
      </div>
    }

    return (
      <section className={"the-menu beige"}>
        <div className="cnt">
          <div className="left sticy">
            <img src={this.state.image} alt="next page" />
          </div>
          <div className="right menu-sections">
            { menu.map((section, i) => {
              return <Section key={i} data={section} />
            })}
          </div>
        </div>

      </section>
    );
  }
}

export default TheMenu;
